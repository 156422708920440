import { useInterval } from 'ahooks';
import React, { useEffect, useMemo } from 'react';

type Props = {
  text: string;
  delay?: number | null;
  interval?: number | null;
  onStart?: () => void;
  onFinish?: () => void;
};

export default function AnimateText({
  text,
  delay = null,
  interval = 50,
  onStart,
  onFinish,
}: Props) {
  const [displayText, setDisplayText] = React.useState('');
  const [isDelay, setIsDelay] = React.useState(false);
  const index = displayText.length;

  const chars = useMemo(() => [...text], [text]);

  useEffect(() => {
    if (text) {
      setDisplayText('');
    }
  }, [text]);

  useInterval(() => {
    if (index === 0 && onStart) {
      onStart();
    }
    if (index === chars.length && onFinish) {
      onFinish();
    }
    if (index < chars.length) {
      setDisplayText((prev) => `${prev}${chars[prev.length] || ''}`);
    }
    if (index === chars.length && !isDelay && delay) {
      setIsDelay(true);
      setTimeout(() => {
        setDisplayText('');
        setIsDelay(false);
      }, delay);
    }
  }, interval as number);

  return <span>{displayText}</span>;
}
