import { isServer } from '@flyer/utils';
import { useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Keyboard, Mousewheel, Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { testLevelsByTag, testLevelsPayload } from '@/store/home';
import ExamPlanet from '@/components/exam/ExamHomeView/ExamPlanet';
import 'swiper/css/effect-coverflow';

export default function ExamBody() {
  const atomData = useAtomValue(testLevelsPayload);
  const currentTag = useAtomValue(testLevelsByTag);
  const [swipeControl, setSwipe] = useState<SwiperClass>();
  const [isMobile, setIsMobile] = useState(false);

  const countDataActive = useMemo(() => atomData.filter((item) => item.active)?.length, [atomData]);

  const onSlideChange = useCallback((swiper: SwiperClass) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('swipeIndex', String(swiper.realIndex));
    }
  }, []);

  useEffect(() => {
    if (swipeControl && !swipeControl?.destroyed && countDataActive) {
      if (countDataActive < 3 || countDataActive > 7) {
        swipeControl.slideToLoop(0);
      }

      if (countDataActive > 2 && countDataActive < 5) {
        swipeControl.slideToLoop(1);
      }

      if (countDataActive > 4 && countDataActive < 7) {
        swipeControl.slideToLoop(2);
      }
    }
  }, [countDataActive, currentTag, swipeControl]);

  useEffect(() => {
    if (swipeControl && !swipeControl?.destroyed && typeof window !== 'undefined') {
      const swipeIndex = window.localStorage.getItem('swipeIndex') || 0;
      if (swipeIndex !== swipeControl.realIndex) {
        swipeControl.slideToLoop(+swipeIndex, 0);
      }
    }
  }, [swipeControl]);

  // useEffect(() => {
  //   const hostname =
  //     typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '';
  //
  //   if (hostname.includes('ieltsminhhuong')) {
  //     // const filterDataHidden = atomData.filter((item) => !item.hidden.includes(lang));
  //     // const filterIelts = atomData.filter((item) => item.slug === 'ielts');
  //     setFinishData(atomData);
  //   } else {
  //     // const newData = atomData.filter((item) => !item.hidden.includes(lang));
  //     // setFinishData(newData);
  //   }
  // }, [atomData, lang]);

  useEffect(() => {
    if (isServer()) {
      return;
    }
    const { userAgent, maxTouchPoints } = window.navigator;
    if (
      (/iphone|ios/i.test(userAgent) &&
        maxTouchPoints > 2 &&
        !(/macintosh/i.test(userAgent) && maxTouchPoints > 2) &&
        !/ipad/i.test(userAgent)) ||
      (/android/i.test(userAgent) &&
        !/(android(?!.*mobile))/i.test(userAgent) &&
        maxTouchPoints > 2) ||
      (/ipad/i.test(userAgent) && maxTouchPoints > 2)
    ) {
      setIsMobile(true);
    }
  }, []);

  return atomData.length === 0 ? null : (
    <Swiper
      className="[&>*>.swiper-slide-active]:!translate-z-0"
      // initialSlide={0}
      speed={300}
      effect="coverflow"
      coverflowEffect={{
        rotate: 0,
        depth: 20,
        scale: 0.45,
        slideShadows: false,
        stretch: -20,
        modifier: 0.7,
      }}
      loopAddBlankSlides={false}
      loopAdditionalSlides={2}
      followFinger={isMobile}
      grabCursor
      allowTouchMove={isMobile}
      watchSlidesProgress
      // slidesPerView="auto"
      loop
      centeredSlides
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      translate="yes"
      pagination={{
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          if (atomData && atomData[index] && !atomData[index]?.isDuplicated) {
            const name = atomData[index]?.name || '';
            const colorMain = atomData[index]?.colorDotIcon || '';
            const colorBorder = atomData[index]?.colorBorder || '';
            const slug = atomData[index]?.slug || '';
            return `
                  <div class="${className} flex flex-col items-center mr-[10px] opacity-[0.5] !w-[17px] !h-[17px] text-[7px] sm:hidden">
                    <div class="border-parent p-[1px] w-[17px] h-[17px] bg-[#2B1A6B] rounded-full mb-[0.125rem]">
                      <div class="border-item p-[2px] w-[15px] h-[15px] border-${slug} bg-[${colorBorder}] rounded-full">
                      <div class="child-item w-[11px] h-[11px] color-main-child-${slug} bg-[${colorMain}] rounded-full"></div>
                      </div>
                    </div>
                    <span class="uppercase text-[5px] text-center text-[#00C2DD]">${name}</span>
                  </div>
                `;
          }

          return '';
        },
      }}
      keyboard={{
        enabled: true,
        onlyInViewport: false,
      }}
      breakpoints={{
        1: {
          slidesPerGroup: 1,
          slidesPerView: 2,
        },
        640: {
          slidesPerGroup: 1,
          slidesPerView: 5,
        },
      }}
      mousewheel
      modules={[Navigation, Pagination, EffectCoverflow, Keyboard, Mousewheel]}
      onSwiper={setSwipe}
      onRealIndexChange={onSlideChange}
    >
      {atomData.map((el, i) => (
        <SwiperSlide key={el.localId}>
          <ExamPlanet data={el} index={i} swipeLength={atomData.length} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
