import { graphql } from '@/__generated__';

export const TestLevelBySlugQuery = graphql(`
  query TestLevelBySlugQuery($slug: String!) {
    testLevelBySlug(slug: $slug) {
      ...TestLevel
    }
  }
`);

export const TestLevelQuery = graphql(`
  query TestLevelQuery($id: ID!) {
    testLevel(id: $id) {
      id: testLevelId
      slug
      name
      playlistId
      description
      descriptionEn
    }
  }
`);

export const GetListTheme = graphql(`
  query GetListThemes($first: Int, $filterBy: ThemeFilter) {
    themes(first: $first, filterBy: $filterBy) {
      edges {
        node {
          id
          themeId
          createdAt
          updatedAt
          name
          nameEn
          imageId
          type
          bookOneId
          bookTwoId
          backgroundId
          bingoId
          progressId
          iconLeftId
          iconRightId
          blockLeft
          blockRight
          progressColor
          iconPrevId
          iconNextId
          borderProgress
          isDefault
          disabled
          imagePreviewId
          imageTitleId
        }
        cursor
      }
      totalCount
    }
  }
`);

export const TestLevelCheckPointsFullQuery = graphql(`
  query CheckPointsFullQuery(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $filterBy: CheckPointFilter
  ) {
    checkPoints: checkPointsFull(
      first: $first
      after: $after
      last: $last
      before: $before
      filterBy: $filterBy
      orderBy: { key: TEST_LEVEL_ORDER }
    ) {
      edges {
        cursor
        node {
          ...TestLevelCheckPoint
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const TestLevelFragment = graphql(/* GraphQL */ `
  fragment TestLevel on TestLevel {
    id: testLevelId
    slug
    name
    playlistId
    typeTestLevel
    description
    descriptionEn
  }
  fragment UserError on UserError {
    message
    code
    field
    param {
      value
    }
  }
`);

export const TestLevelCheckPointFragment = graphql(/* GraphQL */ `
  fragment TestLevelCheckPoint on CheckPoint {
    id: checkPointId
    name
    nameEn
    meta {
      externalUrl
    }
    viewerCompleted
    isPremium
    partsCount
    testCount
    time
    viewerCanAccess
    viewerIsSubscribing
    speakingParts {
      id
      speakingPartId
      partType
    }
    testLevel {
      id
      name
      slug
    }
  }
`);

export const MoreInfoCheckPointQuery = graphql(/* GraphQL */ `
  query MoreInfoCheckPointQuery($id: ID!) {
    checkPoint(id: $id) {
      id
      viewerCanAccess
      name
      nameEn
      checkPointId
      cpType
      type
      examStructures {
        structureType {
          name
          value
        }
      }
      sectionCheckPoints {
        id
        parentStructureType
        checkPointId
        parentPartOrder
      }
    }
  }
`);
export const RecordScoreAttemptFragment = graphql(`
  fragment RecordScoreAttemptFragment on RecordScore {
    transcript
    suggestions {
      audioId
      id
      text
    }
    vocabularyGrammarScore
    pronunciationScore
    interactionScore
    vocabularyGrammarScorePercent
    vocabularyGrammarDecision
    interactionScorePercent
    pronunciationScore
    interactionScore
    overallScore
    coherence {
      decision
      score
    }
    coherenceScore
    vocabulary {
      answerKeyScore
      score
      decision
      cefrScore
      cefrDistribution {
        cefrLevel
        score
      }
      highlightKeywords {
        startIndex
        endIndex
        keyword
      }
    }
    grammar {
      score
      decision
      highlightItems {
        startIndex
        endIndex
        original
        suggestion
      }
    }
    pronunciation {
      generalScore
      intonationScore
      score
      decision
      highlightWords {
        startIndex
        endIndex
        text
        ipa
        nativenessScore
        decision
        audioUrl
        phonemes {
          startIndex
          endIndex
          text
          ipa
          nativenessScore
          decision
        }
      }
    }
    interaction {
      receptionResponding
      fluencyPromptness
      receptionDecision
      fluencyDecision
      fluency {
        wordsPerMinute
      }
    }
  }
`);
export const IeltsSpeakingQuestionFragment = graphql(/* GraphQL */ `
  fragment IeltsSpeakingQuestion on IeltsSpeakingQuestion {
    id
    title
    sampleAnswerHtml
    sampleAnswerAudio {
      id
      url
      subtitle
    }
    timeToSpeak
    timeToThink
    audio {
      id
      url
    }
    contentHtml
    ieltsSpeakingQuestionId
    name
  }
`);

export const IeltsSpeakingCheckPointsQuery = graphql(`
  query CheckPointIeltsQuery($id: ID!) {
    checkPoint(id: $id) {
      id
      name
      nameEn
      description
      descriptionEn
      diamond
      cpType
      viewerCanAccess
      sectionCheckPoints {
        id
        parentStructureType
        checkPointId
        parentPartOrder
      }
      checkPointIeltsSpeakingParts {
        node {
          id
          expiredTime
          order
          defaultShowQuestionContents
          info {
            id
            title
            description
            introductionAudio {
              id
              url
            }
            introductionText
          }
          ieltsSpeakingPartQuestions {
            node {
              ...IeltsSpeakingQuestion
            }
          }
        }
      }
    }
  }
`);

export const ExamCheckPointFullFragment = graphql(/* GraphQL */ `
  fragment ExamCheckPointFull on CheckPoint {
    id
    viewerCanAccess
    name
    nameEn
    checkPointId
    cpType
    type
    testLevel {
      ...TestLevel
    }
    examStructures {
      structureType {
        name
        value
      }
      parts {
        order
        node {
          examPartId
          totalMarks
          id
          maxScore
          answerExplanationHTML
          answerExplanationHTMLEn
          partType
          totalMarks
          contents {
            id
            body {
              formattedText {
                text
                format
                label
                metadataText
              }
              images {
                imageId
                label
                position
              }
            }
          }
          questions {
            isExample
            id
            order
            title
            answerExplanationHTML
            answerExplanationHTMLEn
            details {
              id
              labelOrder
              order
              prefix
              prefix
              detail {
                formattedText {
                  format
                  label
                  metadataText
                  text
                }
                textIndexes {
                  text
                  index
                }
                valueType
                box {
                  height
                  left
                  top
                  width
                }
              }
              result {
                isSelected
                value
                formattedValue {
                  format
                  text
                }
              }
            }
            titlePrefix {
              format
              label
              metadataText
              text
            }
            detailPrefix {
              format
              label
              metadataText
              text
            }
            content {
              format
              label
              metadataText
              text
            }
            choices {
              format
              label
              metadataText
              text
            }
          }
          time
          title
          structureType
        }
        miniCheckPointId
      }
      totalMarks
      time
    }
    sectionCheckPoints {
      id
      parentStructureType
      checkPointId
      parentPartOrder
    }
  }
`);

export const ExamPartAttemptFragment = graphql(`
  fragment ExamPartAttempt on ExamPartAttempt {
    id
    examPartAttemptId
    score
    maxScore
    part {
      id
      examPartId
      title
      partType
      structureType
      maxScore
      questions {
        id
        title
        order
      }
      time
    }
    position
    questionDetailAttempts {
      id
      examQuestionDetailAttemptId
      answer {
        value
        formattedValue {
          text
          format
          label
          metadataText
        }
        isSelected
        valueType
      }
      isCorrect
      result {
        value
        formattedValue {
          text
          format
          label
          metadataText
        }
        isSelected
        valueType
      }
      questionDetail {
        id
        prefix
      }
    }
  }
`);

export const ExamStructureAttemptsFragment = graphql(`
  fragment ExamStructureAttempts on ExamStructureAttempt {
    totalCorrectMarks
    structureType {
      value
      name
    }
    partAttempts {
      ...ExamPartAttempt
    }
  }
`);

export const TestLevelsWithFilter = graphql(`
  query TestLevelsWithFilter($filterBy: TestLevelFilter) {
    testLevels(first: 50, filterBy: $filterBy) {
      nodes {
        ...TestLevel
      }
    }
  }
`);

export const SubmitExamQuestionDetailAttemptMutation = graphql(`
  mutation SubmitExamQuestionDetailMutation($input: ExamSubmitPartAttemptInput!) {
    examSubmitPartAttempt(input: $input) {
      userErrors {
        ...UserError
      }
    }
  }
`);

export const SignUpViewSignUpMutation = graphql(`
  mutation SignUpViewSignUpMutation($input: ViewerSignUpInput!) {
    viewerSignUp(input: $input) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

export const ViewerXpSummaries = graphql(`
  query ViewerXpSummaries(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $filterBy: LearnerXpFilter
  ) {
    viewerXpSummaries(
      first: $first
      after: $after
      last: $last
      before: $before
      filterBy: $filterBy
    ) {
      nodes {
        date
        id
        learnerXpId
      }
    }
  }
`);

export const GetPlayerInfoChallengeByCodeQuery = graphql(`
  query GetPlayersInfoChallengeByCode($code: String!) {
    challengeByCode(code: $code) {
      id: challengeId
      code
      learnerProfile {
        id
        learnerProfileId
        displayName
      }
      players(first: 250) {
        totalCount
        edges {
          node {
            id
            challengePlayerId
            joinedAt
            updatedAt
            learnerProfile {
              id: learnerProfileId
              learnerProfileId
              displayName
              avatarUrl
              frameImage {
                id
                url
              }
            }
            attempt {
              id
              grade
              totalScore
            }
          }
        }
      }
    }
  }
`);

export const GetViewerAchievements = graphql(`
  query ViewerAchievements {
    viewerAchievements {
      id
      learnerAchievementId
      level
      name
      seen
      tier
      value
      dateReceived
      nextLevelValue
      maxLevel
    }
  }
`);

export const ViewerUpdateSeenAchievement = graphql(`
  mutation ViewerSeenAchievements($inputs: [ViewerSeenAchievementInput!]!) {
    viewerSeenAchievements(inputs: $inputs) {
      userErrors {
        code
        field
      }
    }
  }
`);
export const ExamDomain = graphql(`
  query ExamDomain($domain: String!) {
    partnerDomain(domain: $domain) {
      domainName
      id
      meta {
        loginTitle
        logoImage {
          id
          url
        }
        examBackgroundImage {
          url
          id
        }
        examLogoImage {
          url
          id
        }
      }
    }
  }
`);
